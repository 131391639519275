<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                    <b-col cols="12">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Add new payment</h2>
                            <div class="profile__content">
                                <p v-if="addPaymentForm.localError" class="custom-error mb-3">{{ addPaymentForm.localError }}</p>
                                <p v-if="getAdminPaymentsError" class="custom-error mb-3"> {{ getAdminPaymentsError }} </p>
                                <form :class="getDisabledAdminPayment ? 'disabled-element' : ''" @submit.prevent="addAdminPayment" autocomplete="off">
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="profile__label float-right">*Required fields</span>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Uid*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="addPaymentForm.uid" @blur="$v.addPaymentForm.uid.$touch()" :class="[{'input-error' : $v.addPaymentForm.uid.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type user id" autocomplete="off" />
                                                <div v-if="$v.addPaymentForm.uid.$error">
                                                    <p v-if="!$v.addPaymentForm.uid.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addPaymentForm.uid.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Transaction id*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="addPaymentForm.transactionId" @blur="$v.addPaymentForm.transactionId.$touch()" :class="[{'input-error' : $v.addPaymentForm.transactionId.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment transaction id" autocomplete="off" />
                                                <div v-if="$v.addPaymentForm.transactionId.$error">
                                                    <p v-if="!$v.addPaymentForm.transactionId.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addPaymentForm.transactionId.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Subscription type* (2,3,4)</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="addPaymentForm.subscriptionType" @blur="$v.addPaymentForm.subscriptionType.$touch()" :class="[{'input-error' : $v.addPaymentForm.subscriptionType.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment subscription type" autocomplete="off" />
                                                <div v-if="$v.addPaymentForm.subscriptionType.$error">
                                                    <p v-if="!$v.addPaymentForm.subscriptionType.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addPaymentForm.subscriptionType.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Amount*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="addPaymentForm.amount" @blur="$v.addPaymentForm.amount.$touch()" :class="[{'input-error' : $v.addPaymentForm.amount.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment amount" autocomplete="off" />
                                                <div v-if="$v.addPaymentForm.amount.$error">
                                                    <p v-if="!$v.addPaymentForm.amount.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addPaymentForm.amount.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Date* (timestamp)</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="addPaymentForm.date" @blur="$v.addPaymentForm.date.$touch()" :class="[{'input-error' : $v.addPaymentForm.date.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment date" autocomplete="off" />
                                                <div v-if="$v.addPaymentForm.date.$error">
                                                    <p v-if="!$v.addPaymentForm.date.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addPaymentForm.date.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Customer*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="addPaymentForm.customer" @blur="$v.addPaymentForm.customer.$touch()" :class="[{'input-error' : $v.addPaymentForm.customer.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment customer" autocomplete="off" />
                                                <div v-if="$v.addPaymentForm.customer.$error">
                                                    <p v-if="!$v.addPaymentForm.customer.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addPaymentForm.customer.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Invoice*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="addPaymentForm.invoice" @blur="$v.addPaymentForm.invoice.$touch()" :class="[{'input-error' : $v.addPaymentForm.invoice.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment Invoice" autocomplete="off" />
                                                <div v-if="$v.addPaymentForm.invoice.$error">
                                                    <p v-if="!$v.addPaymentForm.invoice.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addPaymentForm.invoice.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <button :disabled="getDisabledAdminPayment" type="submit" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Save payment</button>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    SimpleLineIcons
  },
  data: function () {
    return {
      addPaymentForm: {
        uid: null,
        transactionId: null,
        subscriptionType: null,
        amount: null,
        date: null,
        customer: null,
        invoice: null,
        localError: null
      }
    }
  },
  validations: {
    addPaymentForm: {
      uid: {
        required: required,
        maxLength: maxLength(200)
      },
      transactionId: {
        required: required,
        maxLength: maxLength(200)
      },
      subscriptionType: {
        required: required,
        maxLength: maxLength(200)
      },
      amount: {
        required: required,
        maxLength: maxLength(200)
      },
      date: {
        required: required,
        maxLength: maxLength(200)
      },
      customer: {
        required: required,
        maxLength: maxLength(200)
      },
      invoice: {
        required: required,
        maxLength: maxLength(200)
      }
    }
  },
  methods: {
    addAdminPayment () {
      this.$store.dispatch('setDisabledAdminPayment')
      this.$v.addPaymentForm.$touch()
      if (!this.$v.addPaymentForm.$invalid) {
        this.$store.dispatch('addAdminPayment', { number: this.addPaymentForm.transactionId, type: this.addPaymentForm.subscriptionType, amount: this.addPaymentForm.amount, date: this.addPaymentForm.date, user: this.addPaymentForm.uid, customer: this.addPaymentForm.customer, invoice: this.addPaymentForm.invoice })
          .then(() => {
            this.scrollToTop(500)
            this.$store.dispatch('clearDisabledAdminPayment')
          })
          .catch(error => {
            this.addPaymentForm.localError = error.message
            this.$store.dispatch('clearDisabledAdminPayment')
          })
      } else {
        this.scrollToTop(500)
        this.addPaymentForm.localError = 'Invalid form'
        this.$store.dispatch('clearDisabledAdminPayment')
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledAdminPayment')
  },
  computed: {
    ...mapGetters([
      'getAdminPaymentsError',
      'getDisabledAdminPayment'
    ])
  }
}
</script>
